.skills__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.skills__container > div{
    background-color: var(--color-bg-varient);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 2px solid transparent;
}

.skills__container >div:hover{
    background: transparent;
    border-color: var(--color-bg-varient);
    cursor: default;
    transition: var(--transition);
}

.skills__container > div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
} 

.skills__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.skills__details{
    display: flex;
    gap: 1rem;
}

.skills__details-icon{
    margin: 6px;
    color: var(--color-primary);
}

/*=========Media Quries Medius ==========*/
@media screen and (max-width:1024px){
        .skills__container{
            grid-template-columns: 1fr;
        }

        .skills__container > div{
            width: 80%;
            padding: 2rem;
            margin: 0 auto;
        }
        .skills__content{
            padding: 1rem;

        }
 }
 
 
 /*=========Media Quries Small ==========*/
 @media screen and (max-width:600px){
            .skills__container{
                gap: 1rem;
            }

            .skills__container > div{
                width: 100%;
                padding: 2rem 1rem;
            }
 }
